import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import _get from 'lodash.get';
import _map from 'lodash.map';
import _sortBy from 'lodash.sortby';
import _reverse from 'lodash.reverse';
import styled from 'styled-components';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);
const ReviewForm = loadable(() =>
  import('../components/sub-components/reviewForm')
);
const Button = loadable(() => import('../components/sub-components/button'));

const ReviewBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const ReviewBoxBottomBorder = styled.div`
  height: 1px;
  width: 80%;
  background: #8080806b;
  margin: 0 auto;
`;

const ReviewBody = styled.article`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`;

const NickNameAndLocation = styled.p`
  font-weight: 300;
  font-size: 14px;
  font-size: 17px;
`;

const NickName = styled.span``;

const Location = styled.span`
  font-style: italic;
`;

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Reviews = () => (
  <StaticQuery
    query={graphql`
      query findAllReviews {
        jmonehillart {
          listReviews(
            filter: { visible: { eq: true } }
          ) {
            items {
              body
              location
              nickName
              id
              unixTime
            }
          }
        }
      }
    `}
    render={data => {
      let reviews = _get(data, 'jmonehillart.listReviews.items', []);
      reviews = _reverse(_sortBy(reviews, ['unixTime']));

      const renderReviews = () => {
        return _map(reviews, review => {
          return (
            <ReviewBox key={review.id}>
              <ReviewBody>&quot;{review.body}&quot;</ReviewBody>
              <NickNameAndLocation>
                <NickName>{review.nickName}</NickName>
                <Location>{`- ${review.location}`}</Location>
              </NickNameAndLocation>
              <ReviewBoxBottomBorder />
            </ReviewBox>
          );
        });
      };

      return (
        <InfoPage
          title="Jennifer Mone Hill Artist - Reviews"
          description="Read and create reviews of Jennifer Mone Hill Artist work."
          keywords={[
            'contact',
            'Jennifer Moné Hill',
            'Jen Hill Artist',
            'Jennifer Mone Hill',
            'Jen Hill',
            'Jen Hill Artist',
            'reviews',
          ]}
          imageLink="/works/Spectrum"
          imgSrc="Spectrum-by-Jennifer-Mone-Hill-800.jpg"
          imgSrc2x="Spectrum-by-Jennifer-Mone-Hill-1600.jpg"
          alt="Spectrum by Jennifer Moné Hill"
          customImageWidth={30}
          roomyLayout
        >
          <ContentBox>
            <h2>Reviews</h2>
            <ButtonHolder>
              <Button text="Write a review" large link="/reviews#review-form" />
            </ButtonHolder>
            {renderReviews()}
            <ReviewForm />
          </ContentBox>
        </InfoPage>
      );
    }}
  />
);

export default Reviews;
